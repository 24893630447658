<!-- 充电状态 -->
<template>
  <div>
    <pull-refresh
      :refreshing="isRefreshing"
      :on-refresh="onRefresh"
      pull-height="100vh"
    >
      <div class="power">
        <span class="fault" @click="to_fault()">故障异常</span>
        <!-- 异常结束 -->
        <div v-if="status == 3" class="error">
          <div class="power-plan">异常结束</div>
          <div class="power-info">
            <div class="info-box">
              <div class="info-line"><van-divider>退款情况</van-divider></div>
              <div class="info-item">
                退款原因：<span class="txt">充电异常</span>
              </div>
              <div class="info-item">
                退款金额：<span class="txt">¥{{ info.refund_money }}</span>
              </div>
              <div class="info-item">
                退款方式：<span class="txt">原路退回</span>
              </div>
              <div class="info-item">
                退款时间：<span class="txt">{{
                  info.refund_list &&
                  info.refund_list[0] &&
                  info.refund_list[0].created_at
                    ? info.refund_list[0].created_at
                    : "暂无时间"
                }}</span>
              </div>
            </div>
            <div class="info-box">
              <div class="info-line"><van-divider>充电情况</van-divider></div>
              <div class="info-item">
                充电状态：<span class="txt">异常结束</span>
              </div>
              <div class="info-item">
                充电总时长：<span class="txt">{{ info.spend_hour }}小时</span>
              </div>
              <div class="info-item">
                实际充电时长：<span class="txt">{{ actual_time }}</span>
              </div>
              <div class="info-item">
                开始时间：<span class="txt">{{ info.start_time }}</span>
              </div>
              <div class="info-item">
                结束时间：<span class="txt">{{ info.end_time }}</span>
              </div>
            </div>
            <div class="info-box">
              <div class="info-line"><van-divider>充电桩信息</van-divider></div>
              <div class="info-item">
                充电端口：<span class="txt">{{ info.port_id }}号端口</span>
              </div>
              <div class="info-item">
                设备编号：<span class="txt">{{ info.charger_id }}</span>
              </div>
              <div class="info-item">
                设备地址：<span class="txt">{{
                  info.charger && info.charger.location
                    ? info.charger.location
                    : "暂无地址"
                }}</span>
              </div>
            </div>
            <div class="info-box">
              <div class="info-line"><van-divider>支付信息</van-divider></div>
              <div class="info-item">
                交易方式：<span class="txt">{{
                  list_main.pay_method == 0
                    ? "微信"
                    : list_main.pay_method == 1
                    ? "支付宝"
                    : "账户余额"
                }}</span>
              </div>
              <div class="info-item">
                交易时间：<span class="txt">{{ list_main.created_at }}</span>
              </div>
              <div class="info-item">
                付款金额：<span class="txt">¥{{ list_main.money }}</span>
              </div>
              <div class="info-item">
                订单编号：<span class="txt">{{ list_main.order_num }}</span>
              </div>

              <div v-for="(item, key) in list_other" :key="item.id">
                <p class="power-go">续充{{ key + 1 }}：</p>
                <div class="info-item">
                  交易方式：<span class="txt">{{
                    item.pay_method == 0
                      ? "微信"
                      : item.pay_method == 1
                      ? "支付宝"
                      : "账户余额"
                  }}</span>
                </div>
                <div class="info-item">
                  交易时间：<span class="txt">{{ item.created_at }}</span>
                </div>
                <div class="info-item">
                  付款金额：<span class="txt">¥{{ item.money }}</span>
                </div>
                <div class="info-item">
                  订单编号：<span class="txt">{{ item.order_num }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 充电完成 -->
        <div v-else-if="status == 2" class="over">
          <div class="power-plan">充电完成</div>
          <div class="power-info">
            <div class="info-box">
              <div class="info-line"><van-divider>充电情况</van-divider></div>
              <div class="info-item">
                充电状态：<span class="txt">充电完成</span>
              </div>
              <div class="info-item">
                充电总时长：<span class="txt">{{ info.spend_hour }}小时</span>
              </div>
              <div class="info-item">
                实际充电时长：<span class="txt">{{ actual_time }}</span>
              </div>
              <div class="info-item">
                开始时间：<span class="txt">{{ info.start_time }}</span>
              </div>
              <div class="info-item">
                结束时间：<span class="txt">{{ info.end_time }}</span>
              </div>
            </div>
            <div class="info-box">
              <div class="info-line"><van-divider>充电桩信息</van-divider></div>
              <div class="info-item">
                充电端口：<span class="txt">{{ info.port_id }}号端口</span>
              </div>
              <div class="info-item">
                设备编号：<span class="txt">{{ info.charger_id }}</span>
              </div>
              <div class="info-item">
                设备地址：<span class="txt">{{
                  info.charger && info.charger.location
                    ? info.charger.location
                    : "暂无地址"
                }}</span>
              </div>
            </div>
            <div class="info-box">
              <div class="info-line"><van-divider>支付信息</van-divider></div>
              <div class="info-item">
                交易方式：<span class="txt">{{
                  list_main.pay_method == 0
                    ? "微信"
                    : list_main.pay_method == 1
                    ? "支付宝"
                    : "账户余额"
                }}</span>
              </div>
              <div class="info-item">
                交易时间：<span class="txt">{{ list_main.created_at }}</span>
              </div>
              <div class="info-item">
                付款金额：<span class="txt">¥{{ list_main.money }}</span>
              </div>
              <div class="info-item">
                订单编号：<span class="txt">{{ list_main.order_num }}</span>
              </div>

              <div v-for="(item, key) in list_other" :key="item.id">
                <p class="power-go">续充{{ key + 1 }}：</p>
                <div class="info-item">
                  交易方式：<span class="txt">{{
                    item.pay_method == 0
                      ? "微信"
                      : item.pay_method == 1
                      ? "支付宝"
                      : "账户余额"
                  }}</span>
                </div>
                <div class="info-item">
                  交易时间：<span class="txt">{{ item.created_at }}</span>
                </div>
                <div class="info-item">
                  付款金额：<span class="txt">¥{{ item.money }}</span>
                </div>
                <div class="info-item">
                  订单编号：<span class="txt">{{ item.order_num }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--正在充电 -->
        <div v-else-if="status == 1" class="pend">
          <div class="pend-plan">
            <van-progress
              :percentage="per"
              stroke-width="14"
              color="#FFAE15"
              track-color="#fff"
              :show-pivot="false"
              v-if="per"
            />
            <div class="remain">
              剩余时间：
              <van-count-down
                :time="time"
                format="HH 时 mm 分钟"
                @change="change_time"
                @finish="finish_time"
              />
            </div>
          </div>
          <div class="pend-detail">
            <div>
              <p>充电金额</p>
              <p class="pend-money"><span>￥</span>{{ info.money }}</p>
            </div>
            <span class="line"></span>
            <div>
              <p>使用端口</p>
              <p class="pend-port">{{ info.port_id }}</p>
            </div>
          </div>
          <div class="power-info">
            <div class="info-box">
              <div class="info-line"><van-divider>充电情况</van-divider></div>
              <div class="info-item">
                充电状态：<span class="txt">正在充电</span>
              </div>
              <div class="info-item">
                充电总时长：<span class="txt">{{ info.spend_hour }}小时</span>
              </div>
              <div class="info-item">
                开始时间：<span class="txt">{{ info.start_time }}</span>
              </div>
            </div>
            <div class="info-box">
              <div class="info-line"><van-divider>充电桩信息</van-divider></div>
              <div class="info-item">
                充电端口：<span class="txt">{{ info.port_id }}号端口</span>
              </div>
              <div class="info-item">
                设备编号：<span class="txt">{{ info.charger_id }}</span>
              </div>
              <div class="info-item">
                设备地址：<span class="txt">{{
                  info.charger && info.charger.location
                    ? info.charger.location
                    : "暂无地址"
                }}</span>
              </div>
            </div>
            <div class="info-box">
              <div class="info-line"><van-divider>支付信息</van-divider></div>
              <div class="info-item">
                交易方式：<span class="txt">{{
                  list_main.pay_method == 0
                    ? "微信"
                    : list_main.pay_method == 1
                    ? "支付宝"
                    : "账户余额"
                }}</span>
              </div>
              <div class="info-item">
                交易时间：<span class="txt">{{ list_main.created_at }}</span>
              </div>
              <div class="info-item">
                付款金额：<span class="txt">¥{{ list_main.money }}</span>
              </div>
              <div class="info-item">
                订单编号：<span class="txt">{{ list_main.order_num }}</span>
              </div>

              <div v-for="(item, key) in list_other" :key="item.id">
                <p class="power-go">续充{{ key + 1 }}：</p>
                <div class="info-item">
                  交易方式：<span class="txt">{{
                    item.pay_method == 0
                      ? "微信"
                      : item.pay_method == 1
                      ? "支付宝"
                      : "账户余额"
                  }}</span>
                </div>
                <div class="info-item">
                  交易时间：<span class="txt">{{ item.created_at }}</span>
                </div>
                <div class="info-item">
                  付款金额：<span class="txt">¥{{ item.money }}</span>
                </div>
                <div class="info-item">
                  订单编号：<span class="txt">{{ item.order_num }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 未开始 -->
        <div v-else-if="status == 0" class="pend">
          <div class="pend-plan">
            <van-progress
              percentage="0"
              stroke-width="14"
              color="#FFAE15"
              track-color="#fff"
              :show-pivot="false"
              v-if="per"
            />
            <div class="remain">
              剩余时间：{{ info.spend_hour }}小时 00 分钟
            </div>
          </div>
          <div class="pend-detail">
            <div>
              <p>充电金额</p>
              <p class="pend-money"><span>￥</span>{{ info.money }}</p>
            </div>
            <span class="line"></span>
            <div>
              <p>使用端口</p>
              <p class="pend-port">{{ info.port_id }}</p>
            </div>
          </div>
          <div class="power-info">
            <div class="info-box">
              <div class="info-line"><van-divider>充电情况</van-divider></div>
              <div class="info-item">
                充电状态：<span class="txt">未开始</span>
              </div>
              <div class="info-item">
                充电总时长：<span class="txt">{{ info.spend_hour }}小时</span>
              </div>
              <div class="info-item">
                开始时间：<span class="txt">{{ info.start_time }}</span>
              </div>
            </div>
            <div class="info-box">
              <div class="info-line"><van-divider>充电桩信息</van-divider></div>
              <div class="info-item">
                充电端口：<span class="txt">{{ info.port_id }}号端口</span>
              </div>
              <div class="info-item">
                设备编号：<span class="txt">{{ info.charger_id }}</span>
              </div>
              <div class="info-item">
                设备地址：<span class="txt">{{
                  info.charger && info.charger.location
                    ? info.charger.location
                    : "暂无地址"
                }}</span>
              </div>
            </div>
            <div class="info-box">
              <div class="info-line"><van-divider>支付信息</van-divider></div>
              <div class="info-item">
                交易方式：<span class="txt">{{
                  list_main.pay_method == 0
                    ? "微信"
                    : list_main.pay_method == 1
                    ? "支付宝"
                    : "账户余额"
                }}</span>
              </div>
              <div class="info-item">
                交易时间：<span class="txt">{{ list_main.created_at }}</span>
              </div>
              <div class="info-item">
                付款金额：<span class="txt">¥{{ list_main.money }}</span>
              </div>
              <div class="info-item">
                订单编号：<span class="txt">{{ list_main.order_num }}</span>
              </div>

              <div v-for="(item, key) in list_other" :key="item.id">
                <p class="power-go">续充{{ key + 1 }}：</p>
                <div class="info-item">
                  交易方式：<span class="txt">{{
                    item.pay_method == 0
                      ? "微信"
                      : item.pay_method == 1
                      ? "支付宝"
                      : "账户余额"
                  }}</span>
                </div>
                <div class="info-item">
                  交易时间：<span class="txt">{{ item.created_at }}</span>
                </div>
                <div class="info-item">
                  付款金额：<span class="txt">¥{{ item.money }}</span>
                </div>
                <div class="info-item">
                  订单编号：<span class="txt">{{ item.order_num }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="pend-btn">
            <button class="stop" @click="stop()">停止充电</button>
            <button class="continue" @click="goon()">续充</button>
          </div>
        </div>
      </div>
    </pull-refresh>
    <div class="pend-btn" v-if="status == 1">
      <button class="stop" @click="stop()">停止充电</button>
      <button class="continue" @click="goon()">续充</button>
    </div>
    <!--充电支付-->
    <van-action-sheet v-model="show" title="充电支付">
      <div class="pay">
        <div class="pay-box">
          <div class="pay-title">
            充电端口：<span class="pay-port">{{ info.port_id }}号</span>
          </div>
        </div>
        <div class="pay-box">
          <div class="pay-title">
            充电金额：<span class="pay-hour">1元/{{ unit }}小时</span>
          </div>
          <ul class="money-list">
            <li
              :class="item == money ? 'active' : ''"
              v-for="(item, index) in money_list"
              :key="index"
              @click="change_money(item)"
            >
              {{ item }}元
            </li>
          </ul>
          <div class="pay-title">充电方式：</div>
          <ul class="way-list">
            <van-radio-group v-model="radio">
              <li>
                <p class="way-title">
                  <i class="iconfont t-icon-weixin"></i>微信支付
                </p>
                <van-radio name="1">
                  <template #icon="">
                    <img
                      class="img-icon"
                      :src="radio == 1 ? activeIcon : inactiveIcon"
                    />
                  </template>
                </van-radio>
              </li>
              <li>
                <p class="way-title">
                  <i class="iconfont t-icon-qianbao"></i>余额支付<span
                    class="way-sun"
                    >（余额：{{ balance > 0 ? "￥" + balance : 0 }}）</span
                  >
                </p>
                <van-radio name="2" :disabled="balance == 0">
                  <template #icon="">
                    <img
                      class="img-icon"
                      :src="radio == 2 ? activeIcon : inactiveIcon"
                    />
                  </template>
                </van-radio>
              </li>
            </van-radio-group>
          </ul>
          <div class="tip">
            <i class="iconfont t-icon-jisuanfangshi"></i
            >设备若发生中断，按实际充电时间收费
          </div>
        </div>
        <div class="pay-all d-flex">
          <div>
            <p>
              实付金额：<span class="pay-total"><i>￥</i>{{ money }}</span>
            </p>
            <p>
              充电时长：<span>{{ hours }}小时</span>
            </p>
          </div>
          <button class="pay-button" @click="pay">去支付</button>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import Vue from "vue";
import { Dialog } from "vant";
Vue.use(Dialog);
import { getData, postData } from "@/api/user";
import { _debounce } from "@/libs/util";
import wx from "weixin-js-sdk";
import { setTimeout } from "timers";
import pullRefresh from "@/components/pullRefresh.vue";
export default {
  data() {
    return {
      id: "",
      info: {},
      balance: 0,
      spend_time: 0,
      status: -1, // 1正在充电，2充电完成，3异常结束
      recode_status: -1,
      time: 0,
      per: 0,
      show: false,
      money_list: [1, 2, 3, 4],
      money: 1, // 总共支付多少钱
      unit: 4, // 每小时多少元
      hours: 0, // 充电时长
      radio: 2,
      activeIcon:
        "https://ebaina.oss-cn-hangzhou.aliyuncs.com/res/images/202106/25/chongdian/2021625-17956.png",
      inactiveIcon:
        "https://ebaina.oss-cn-hangzhou.aliyuncs.com/res/images/202106/25/chongdian/2021625-17941.png",
      list_main: {},
      list_other: [],
      isRefreshing: false,
      actual_time: "", //实际充电时长
      pay_flag: false, // 防止多次触发
    };
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f7f7f7");
  },
  components: {
    pullRefresh,
  },
  created() {
    this.id = this.$route.query.id;
    this.recode_status = this.$route.query.status
      ? this.$route.query.status
      : -1;
    this.get_power();
    this.get_balance();
  },
  methods: {
    // 账户余额
    get_balance() {
      getData({
        url: "api/charging/user/user_balance",
        data: {},
      }).then((res) => {
        let data = res.data.data;
        if (res.data.code === 200) {
          this.balance = data.data || 0;
        } else if (res.data.code === 422) {
          this.$toast(res.data.data.msg);
        }
      });
    },
    get_power() {
      getData({
        url: `api/charging/charger/charger_status_info`,
        data: {
          id: this.id,
        },
      }).then((res) => {
        var data = res.data.data.data;
        if (res.data.code === 200) {
          this.info = data;
          this.status =
            this.recode_status != -1 ? this.recode_status : data.status;

          // 倒计时
          this.time =
            parseInt(data.surplus_hour) * 60 * 60 * 1000 +
            parseInt(data.surplus_min) * 60 * 1000;
          this.spend_time = data.spend_time;
          let per = (1 - this.time / (data.spend_time * 60 * 1000)) * 100;
          this.per = per >= 100 ? 100 : per;

          // 每小时多少钱
          this.unit =
            data.charger && data.charger.village && data.charger.village.hours
              ? data.charger.village.hours
              : 4;

          // 实际充电时长
          let time = this.info.actual_time;
          if (time > 0) {
            let h = this.format(parseInt(time / 60));
            let m = this.format(time % 60);
            this.actual_time = h + "小时" + m + "分钟";
          } else {
            this.actual_time = "00小时00分";
          }

          // 续充
          this.list_other = [];
          let list = this.info.consume_detail;
          if (list && list.length > 0) {
            list.forEach((v) => {
              if (v.recharge_type == 0) {
                this.list_main = v;
              } else {
                this.list_other.push(v);
              }
            });
          }
        } else if (res.data.code === 422) {
          this.$toast(res.data.data.msg);
        }
      });
    },
    format(num) {
      return num < 10 ? "0" + num : num;
    },
    change_time(e) {
      let time =
        e.hours * 60 * 60 * 1000 +
        e.minutes * 60 * 1000 +
        e.seconds * 1000 +
        e.milliseconds;
      let per = (1 - time / (this.spend_time * 60 * 1000)) * 100;
      this.per = per >= 100 ? 100 : per;
    },
    finish_time() {
      this.get_power();
      this.get_balance();
    },
    to_fault() {
      this.$router.push({
        name: "fault",
        query: {
          charger_id: this.info.charger_id,
          port_id: this.info.port_id,
          type: "power",
        },
      });
    },

    // 停止充电
    stop() {
      Dialog.confirm({
        title: "确认要停止此次充电？",
        message: "停止充电后，将原路退还部分金额",
        confirmButtonColor: "#3DC48D",
      })
        .then(() => {
          postData({
            url: "api/charging/charger/end_charging",
            data: {
              id: this.id,
              port_id: this.info.port_id,
            },
          }).then((res) => {
            if (res.data.code === 200) {
              this.$toast({
                message: res.data.data.msg,
                forbidClick: true,
              });
              setTimeout(() => {
                this.get_power();
                this.get_balance();
              }, 1500);
            } else {
              this.$toast(res.data.data.msg);
            }
          });
        })
        .catch(() => {});
    },
    // 续充
    goon() {
      this.show = true;
      this.hours = this.money * this.unit;
      this.radio = 1;
    },
    pay: _debounce(function () {
      let that = this;
      if (this.radio == 1) {
        postData({
          url: "api/charging/consume/pay",
          data: {
            charger_id: this.info.charger_id,
            port_id: this.info.port_id,
            consume_id: this.info.id,
            money: this.money,
          },
        }).then((res) => {
          let pay_data = res.data;

          getData({
            url: "api/get_js_config",
            data: {
              url: window.location.href.split("#")[0],
            },
          }).then((res) => {
            let data = res.data.data;
            wx.config({
              debug: false,
              appId: data.appId + "",
              timestamp: data.timestamp,
              nonceStr: data.nonceStr + "",
              signature: data.signature + "",
              jsApiList: ["checkJsApi", "chooseWXPay"],
            });
            wx.error(function (res) {
              alert("出错了：" + res.errMsg); //这个地方的好处就是wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
            });

            wx.ready(function () {
              wx.checkJsApi({
                // 判断当前客户端版本是否支持指定JS接口
                jsApiList: ["chooseWXPay"],
                success: function (res) {
                  if (res.checkResult.chooseWXPay === true) {
                    wx.chooseWXPay({
                      appId: pay_data.appId + "",
                      timestamp: pay_data.timestamp,
                      nonceStr: pay_data.nonceStr + "",
                      package: pay_data.package + "",
                      signType: pay_data.signType + "",
                      paySign: pay_data.paySign + "",
                      prepayId: pay_data.prepayId + "",
                      success: function () {
                        // that.$toast("支付成功");
                        location.reload();
                        // that.$router.push({
                        //   name: "power",
                        //   query: { id: that.info.id },
                        // });
                      },
                      fail() {
                        that.$toast("支付失败");
                      },
                    });
                  } else {
                    alert("抱歉，当前客户端版本不支持");
                  }
                },
                fail: function (res) {
                  // 检测getNetworkType该功能失败时处理
                  alert("检测getNetworkType该功能失败" + res);
                },
              });
            });
            /* 处理失败验证 */
            wx.error(function (res) {
              // config 信息验证失败会执行error函数,如签名过期导致验证失败,具体错误信息可以打开config的debug模式查看,也可以在返回的res参数中查看,对于SPA可以在这里更新签名
              alert("配置验证失败: " + res.errMsg);
            });
          });

          if (res.data.code === 422) {
            this.$toast(res.data.data.msg);
            setTimeout(() => {
              this.show = false;
              this.get_power();
              this.get_balance();
            }, 1500);
          }
        });
      } else {
        this.balance_pay();
      }
    }),
    // 余额支付
    balance_pay: _debounce(function () {
      if (this.pay_flag) return;
      this.pay_flag = true;

      postData({
        url: "api/charging/consume/pay_by_balance",
        data: {
          charger_id: this.info.charger_id,
          port_id: this.info.port_id,
          consume_id: this.info.id,
          pay_money: this.money,
        },
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          this.$toast(data.data.msg);
          // this.get_current_user_charging();
          location.reload();
        } else if (data.code === 422) {
          this.$toast(data.data.msg);
          setTimeout(() => {
            this.show = false;
            this.get_power();
            this.get_balance();
          }, 1500);
          this.pay_flag = false;
        } else {
          this.pay_flag = false;
        }
      });
    }),
    change_money(item) {
      this.money = item;
      this.hours = item * this.unit;
    },
    refresh() {
      this.get_power();
      this.get_balance();
    },
    onRefresh() {
      this.isRefreshing = true;
      setTimeout(() => {
        this.refresh();
        this.isRefreshing = false;
      }, 2000);
    },
  },
  beforeRouteEnter(to, from, next) {
    var u = navigator.userAgent;

    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

    // XXX: 修复iOS版微信HTML5 History兼容性问题

    if (isiOS && to.path !== location.pathname) {
      // 此处不可使用location.replace

      location.assign(to.fullPath);
    } else {
      next();
    }
  },
};
</script>

<style lang="less">
.power {
  .fault {
    position: absolute;
    top: 38px;
    right: 12px;
    height: 24px;
    width: auto;
    padding: 0 12px;
    border: 1px solid #fff;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    color: #fff;
  }
  .pend {
    padding: 190px 12px 84px;
    background: url(https://ebaina.oss-cn-hangzhou.aliyuncs.com/res/images/202106/25/chongdian/2021625-17224.png)
      no-repeat top left;
    background-size: 100%;
    .power-info {
      background: #fff;
    }
  }
  .pend-plan {
    padding: 0 38px;
    margin-bottom: 24px;
  }
  .remain {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 14px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #242157;
  }
  .pend-detail {
    display: flex;
    align-items: center;
    padding: 12px 40px 15px;
    margin-bottom: 10px;
    background: #fff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    font-size: 14px;
    line-height: 20px;
    color: #242157;
    text-align: center;
  }
  .line {
    width: 1px;
    height: 37px;
    background: #f2f2f2;
    margin: 0 60px;
  }
  .pend-money {
    margin-top: 8px;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    color: #242157;
    span {
      font-size: 14px;
    }
  }
  .pend-port {
    margin-top: 8px;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    color: #3dc48d;
  }
  .power-info {
    .info-box {
      padding: 1px 16px;
      margin-bottom: 4px;
    }
    .info-line {
      padding: 0 70px;
    }
    .info-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 21px;
      font-size: 14px;
      line-height: 20px;
      color: #666666;
      .txt {
        font-size: 15px;
        color: #333;
        flex: 1;
        text-align: right;
        margin-left: 20px;
      }
    }
  }
  .van-divider {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #333;
  }
  .van-divider::after,
  .van-divider::before {
    background: #f2f2f2;
  }
  .power-go {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #333;
  }
  .power-plan {
    margin-bottom: 28px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    color: #03014b;
  }
  .error {
    background: #fff
      url(https://ebaina.oss-cn-hangzhou.aliyuncs.com/res/images/202106/25/chongdian/2021625-17426.png)
      no-repeat top left;
    background-size: 100%;
    padding: 180px 0 0;
    .power-info {
      padding: 0 18px 30px;
    }
  }
  .over {
    background: #fff
      url(https://ebaina.oss-cn-hangzhou.aliyuncs.com/res/images/202106/25/chongdian/2021625-17426.png)
      no-repeat top left;
    background-size: 100%;
    padding: 180px 0 0;
    .power-info {
      padding: 0 18px 30px;
    }
  }
}
.pay {
  .img-icon,
  .van-checkbox__icon {
    height: 20px;
    width: 20px;
  }
}
.pend-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 12px;
  background: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    height: 44px;
    width: 172px;
    border: 1px solid #3dc48d;
    border-radius: 22px;
    font-size: 16px;
    font-weight: 600;
    line-height: 44px;
    color: #3dc48d;
    background: #fff;
    padding: 0;
  }
  .continue {
    color: #fff;
    background: #3dc48d;
  }
}
</style>